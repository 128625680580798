import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import router from "../router/index";
import {
  checkToken,
  deleteAccount,
  getProfile,
  loginUser,
  registerUser,
  verifyEmail,
} from "../services/api";
import { useShop } from "./shop";
const toast = useToast();

const state = () => ({
  tokenUser: null,
  userIsLoggedIn: false,
  user: [],
  docsRepresentante: false,
  lockedViews: [],
});

const actions = {
  async loginUser(data) {
    const response = await loginUser(data);
    const dataUser = await response.data.data;

    localStorage.setItem("token_auth", dataUser.token);
    localStorage.setItem("id_user", dataUser.id);
    localStorage.setItem("pc_premio", dataUser.pc_premio);
    localStorage.setItem("funcao", dataUser.grupo);
    localStorage.setItem("pessoa_id", dataUser.pessoa_id);
    localStorage.setItem("id_integrador", dataUser.id_integrador);
    localStorage.setItem("uuiddoc", dataUser.uuiddoc);
    localStorage.setItem("locked_views", JSON.stringify(dataUser.locked_views));

    if (
      dataUser.doc_representante.url_contratosocial &&
      dataUser.doc_representante.url_documentofoto
    ) {
      this.docsRepresentante = true;
    } else {
      this.docsRepresentante = false;
    }

    this.verifyIfUserIsLoggedIn();

    toast.success("Logado com sucesso");
    this.tokenUser = dataUser;

    router.push({ name: "home" });
  },

  async registerUser(data) {
    try {
      const response = await registerUser(data);
      const dataUser = await response.data.data;

      return dataUser;
    } catch (err) {
      return Promise.reject(err);
    }
  },

  async verifyEmail() {
    const result = await verifyEmail({ email: this.user.email });

    return result.data;
  },

  async deleteUserAccount(code: string) {
    try {
      await deleteAccount(code);
      return true;
    } catch (error) {
      return false;
    }
  },

  logoutUser() {
    const storeShop = useShop();
    this.tokenUser = null;
    localStorage.removeItem("token_auth");
    localStorage.removeItem("shop");
    localStorage.removeItem("products");
    localStorage.removeItem("registerDataCustomer");
    localStorage.removeItem("idGeradorSelecionado");
    storeShop.products = { items: [] };
    storeShop.registerData = [];
    localStorage.clear();

    toast.success("Deslogado com sucesso!");

    router.push({ name: "login" });
  },

  async verifyIfUserIsLoggedIn() {
    try {
      const response = await checkToken();
      const dataToken = await response.data;

      if (dataToken.success) {
        this.userIsLoggedIn = true;
        this.user = dataToken.data;
        this.lockedViews = dataToken.data.locked_views;
        return;
      }

      this.userIsLoggedIn = false;

      return dataToken;
    } catch (error) {
      if (error.response?.status === 401) {
        toast.error("Não autorizado");
        this.userIsLoggedIn = false;
        localStorage.removeItem("token_auth");
        router.push({ name: "login" });
      }

      return error;
    }
  },
  async getProfileUser() {
    const response = await getProfile();
    const user = response.data.data;
    this.user = user.user;

    return user;
  },
};

const getters = {
  getPcPremioUser() {
    return this.user.pc_premio;
  },
  getIdUser() {
    return this.user.id;
  },
  getPessoaId() {
    return this.user.pessoa_id;
  },
  getFuncao() {
    return this.user.grupo ? this.user.grupo : this.user.grupos_id;
  },
  getName() {
    return this.user.name;
  },
};

export const useAuth = defineStore("auth", {
  state,
  getters,
  actions,
  persist: true,
});
